import 'owl.carousel';
import 'isotope-layout/dist/isotope.pkgd.min.js';
export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // fonction qui gérer l'affichage du block recherche
    $('.search i').click(function(){
      $('.block-search').slideDown();
    });

    $('.close-btn ').click(function(){
      $('.block-search').slideUp();
    })
    // carousel logos
    $(document).ready(function(){
      $('.owl-carousel').owlCarousel({
        autoWidth:true,
        margin: 25,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000
      });
    });

    // Grid Filter 
    var $grid = $('.grid-filter').isotope({
      itemSelector: '.grid-item',
      layoutMode: 'fitRows'
    });

    // filter items 
    $('.filters').on( 'click', 'a', function(e) {
      e.preventDefault();
      $(this).parent().addClass('active').siblings().removeClass('active');
      var filterValue = $(this).attr('data-filter');
      $grid.isotope({ filter: filterValue });
    });

    // consentement 
    $('.cookies-link').click(function(){
      // eslint-disable-next-line
      Didomi.preferences.show();
    })

    // Menu 
    $('.burger-menu').click(function(){
      $('.nav-primary').slideDown(200);
      let h = $('.nav').innerHeight();
      $('.banner').animate({ marginBottom: h+'px'}, 200);
      $('.burger-menu').fadeOut(function(){      
        $('.burger-close').fadeIn();
      });

    });
    $('.burger-close').click(function(){
      $('.nav-primary').slideUp(200);
      $('.banner').animate({ marginBottom: '42px'}, 200);
      $(this).fadeOut(function(){      
        $('.burger-menu').fadeIn();
      });

    });
  },
};
